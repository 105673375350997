.form-control input {
  display: block;
}
.form-control input[type="radio"], .form-control input[type="checkbox"] {
  display: inline;
  margin-right: 7px;
  width: auto;
}
.form-control .flexbox {
  display: -webkit-flex;
  display: flex;
}
.form-control .flexbox input:first-child {
  margin-right: 10px;
}
.form-control .flex-1 {
  -webkit-flex: 1;
  flex: 1;
}
.form-control .flex-2 {
  -webkit-flex: 2;
  flex: 2;
}
.form-control .flex-3 {
  -webkit-flex: 3;
  flex: 3;
}
.form-control .flex-4 {
  -webkit-flex: 4;
  flex: 4;
}
.form-control a {
    text-decoration-style: dotted;
    text-decoration-color: #fbbb21;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    color: inherit;
    font-weight: 600;
}
.form-control .checkers {
  border-right: 1px solid #cecece;
}
.form-control .fr_displayInlineBlock {
  display: inline-block;
}
@media screen and (max-width: 600px){
    .form-control .flexbox {
        display: block;
    }    
}