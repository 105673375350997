.dates--block img {
    height: 22px;
    margin-bottom: -3px;
    margin-right: 7px;
}
.dates--block p {
    line-height: 0em;
}
.newKanton {
    border: 1px solid #8E8979;
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 260px;
}
@media screen and (max-width: 400px) {
    .newKanton {
        width: 100%;
    }
    
}