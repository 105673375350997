.modal {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  height: auto;
  max-height: 95vh;
  overflow: scroll;
}

.modal__header {
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}
.modal__content input, .modal__content select {
  width: 100%;
  display: inline;
    box-sizing: border-box;
    width: 100%;
    font: inherit;
    border: 1px solid #cbbd9a;
    background: white;
    padding: .6rem;
}
.modal__content input:focus {
  outline: none;
    background: #ebebeb;
    border-color: #510077;
}
.modal__content label {
  padding-left: 10px;
  display: block;
  margin-top: 10px;
  color:#2a006e;

}
.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
