.button {
    font: inherit;
    padding: 1.1rem 1.5rem;
    border: 1px solid #fecc33;
    border-radius: 1px;
    background: #fecc33;
    color: black;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: #e0d6c6;
    border-color: #fecc33;
  }
  
  .button--inverse {
    background: transparent;
    color: #e0d6c6;
    color: black;
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: black;
    background: #fecc33;
  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
    color:white;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--small {
    font-size: 0.8rem;
  }
  
  .button--big {
    font-size: 1.5rem;
  }