.card {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    padding: .6rem 1rem;
    overflow: hidden;
    background: white;
    list-style: none;
    margin-top: 5px;
    cursor: pointer;
  }
  .card.card--chosen {
    border: 3px solid #fecc33;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    background-color: #fecc33;
    box-sizing: border-box;
  }
  .card.hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }
  