.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fecc33;
  border-color: #fecc33 transparent #fecc33 transparent;
  animation: lds-dual-ring 1.2s ease-in-out infinite;
}

.loading-spinner__overlay {
  z-index: 10000;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
