.logo--header .header {
  display: block;
  box-sizing: border-box;
}
.logo--header .header__top {
  height: 48px;
}
.logo--header .bg-gray-lightest {
  background-color: #f0eeeb !important;
}
@media (min-width: 768px) {
  .logo--header .pb-md-4,
  .logo--header .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
.logo--header .bg-white {
  background-color: #fff !important;
}
.logo--header .header__branding {
  height: 65px;
  box-sizing: border-box;
}
.logo--header .container-fluid {
  width: 100%;
  max-width: 1920px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.logo--header .header__branding a {
  position: relative;
  top: -30px;
  bottom: 0;
  left: 16px;
  right: 0;
  display: table;
  width: auto;
  text-align: right;
}
@media (min-width: 769px) {
  .logo--header .header__branding a {
    top: -50px;
  }
}
.logo--header .header__branding a img {
  width: 100px;
  max-width: 100%;
  margin-left: 0;
  pointer-events: none;
}
@media (min-width: 769px) {
  .logo--header .header__branding a img {
    top: -20px;
  }
}
@media (max-width: 768px) {
    .logo--header .header__branding a img {
      width: 65px;
    }
  }
.logo--header img {
    vertical-align: middle;
    border-style: none;
}
@media (max-width: 767px){
    .logo--header .header__branding a {
        top: 10px;
    }
}
@media (max-width: 767px){
    .logo--header .header__branding {
        height: 52px;
    }
}
@media (max-width: 767px){
   .logo--header .header__top {
        height: inherit;
    }
}
