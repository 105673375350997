.info .info--column p,
.info .info--column img {
    float: left;
}
.info .info--column img {
    height: 25px;
}
.info .info--column p {
    line-height: 0em;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
}
.info .tooltip--width {
    width: 80%;
    max-width: 400px;
}