body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Helvetica Neue", sans-serif;
}

.App {
  width: 80%;
  max-width: 600px;
  margin: 20px auto 0 auto;
  font-family: "Poppins", sans-serif;
  color: #333333;
}
.App h1 {
  font-size: 1.8em;
  letter-spacing: 0.05em;
}
.App p,
.App div {
  font-size: 0.95em;
}
.App .fr_fontXs {
  font-size: 0.6em;
}

/* Colour */
.fr_bgrBlack {
  background-color: black;
}
.fr_bgrYellow {
  background-color: #f7eb5c;
}
.fr_bgrPink {
  background-color: #f02c95;
}
.fr_bgrWhite {
  background-color: white;
}

/* typo */
.fr_fontYellow {
  color: #fecc33;
}
.fr_fontDark {
  color: #333333;
}
.fr_fontBlack {
  color: black;
}
.fr_fontWhite {
  color: white;
}
.fr_fontLight {
  font-weight: 400;
}
.fr_fontBold {
  font-weight: 600;
}
.fr_textRight {
  text-align: right;
}
.fr_textCenter {
  text-align: center;
}
.App .fr_tinyFont,
.fr_tinyFont {
  font-size: 0.7em;
}
.fr_underlineYellow {
  border-bottom: 3px solid #fecc33;
}

/* Display */
.fr_posAbs {
  position: absolute;
}
.fr_posRel {
  position: relative;
}
.fr_posFix {
  position: fixed;
}
.fr_displayBlock {
  display: block;
}
.fr_displayInlineBlock {
  display: inline-block;
}
.fr_displayInline {
  display: inline;
}
.fr_floatLeft {
  float: left;
}
.fr_floatRight {
  float: right;
}

.fr_borderBox {
  box-sizing: border-box;
}

.fr_marginTopS {
  margin-top: 5px;
}
.fr_marginTopM {
  margin-top: 20px;
}
.fr_marginTopXl {
  margin-top: 50px;
}
.fr_marginBottomM {
  margin-bottom: 20px;
}
.fr_marginBottomL {
  margin-bottom: 45px;
}
.fr_marginBottomXl {
  margin-bottom: 50px;
}
.fr_marginLeftS {
  margin-left: 5px;
}
.fr_marginRightM {
  margin-right: 10px;
}

.fr_paddingXs {
  padding: 2px;
}
.fr_paddingS {
  padding: 7px;
}
.fr_paddingL {
  padding: 30px;
}
.fr_paddingClaim {
  padding: 0 15px;
}
.fr_padding-left0 {
  padding-left: 0px;
}
.fr_padding1rem {
  padding: 1rem;
}

.fr_hide {
  display: none !important;
}
.fr_clear {
  clear: both;
}

.fr_width50 {
  width: 50%;
}
.fr_width66 {
  width: 66%;
}

.fr_iconS {
  width: 25px;
}

.slide-in-right-enter {
  transform: translateX(100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 200ms;
}
.fr_pointer {
  cursor: pointer;
}
.fr-button-label {
  cursor: pointer;
  background-color: #fecc33;
  padding: 6px 20px;
  width: 180px;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 10;
}
#file {
  position: absolute;
  z-index: 1;
  top: 0;
  padding-left: 155px;
}
#file::file-selector-button {
  border: none;
  width: 1px;
  margin-right: 30px;
  background-color: white;
  color: white;
}
